<template>
<div>
    <div id="movies-box">
        <div class="grid-col"></div>
        <div class="grid-col"></div>
        <div class="grid-col"></div>
        <router-link class="movie-cell" v-for="(movie, i) in $options.movies" :key="i" :to="`/movies/${i}`">
            <div class="card hover-highlight">
                <img :src="movie.cover">
            </div>
        </router-link>
    </div>
    <div id="modal" :class="{'modal-shown': shouldShowModal, 'modal-hidden': !shouldShowModal}">
        <router-view>
        </router-view>
    </div>
</div>
</template>

<script>
import NavBar from '../components/NavBar'
import movies from '../assets/data/movies'
import Colcade from '../helper/Colcade'

export default {
    name: 'movies-page',
    title: 'Movies',
    components: {
        // NavBar
    },
    movies: movies,
    mounted() {
        this.colcade = new Colcade('#movies-box', {
            columns: '.grid-col',
            items: '.movie-cell'
        })
    },
    computed: {
        shouldShowModal() {
            return this.$route.params.movieId!==undefined
        }
    }
}
</script>

<style lang="scss" scoped>
@import '@/scss/grid.scss';
@include grid(3, movies-box);

// set modal size and backdrop
@import '@/scss/modal';
@include modal-backdrop;

#movies-box .movie-cell {
    div {
        overflow: hidden;
        margin-bottom: 6vw;
        @media screen and (min-width: $tabletWidth) {
            margin-bottom: 3vw;
        }

        img {
            display: block;
            width: 100%;
        }
    }
}
</style>